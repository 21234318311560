import React, { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'i18n';
import { v4 as uuidv4 } from 'uuid';
import { makeStyles } from 'tss-react/mui';
import { Container, Grid, Link, NoSsr, Theme, Typography, useMediaQuery } from '@mui/material';
import Image from 'components/common/Image';

import { Market } from 'api/general/models';

import useAuthProps from 'hooks/useAuthProps';

import { useDispatch } from 'react-redux';
import { isPast } from 'date-fns';
import { DetectMarketActions } from 'store/actions';
import { bannerData, eventsPromo, mainBanner, tagsRows } from './dataByMarkets';
import SupportersTittledLogosList from './SupportersTittledLogosList';
import EventsSectionContainer from './HomepageSections/EventsSectionContainer';
import EventPromoCardsContainer from './EventPromoCardsContainer';
import HomepageBanner from './HomepageBanner';
import HomepageBannerTitle from './HomepageBannerTitle';
import EventsTagsRow from './EventsTagsRow';
import { trackPage } from '../screens/ScrenTracker';
import DynamicSectionsContainer from './HomepageSections/DynamicSectionsContainer';
import HomepageEmptyState from './HomepageEmptyState';
import { useHashParams } from '../../hooks';
import JLivePassportCreatedBanner from '../userProfile/UserJLivePassportSection/JLivePassportCreatedBanner';

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: 'white',
    overflow: 'hidden',
  },
  bannerContainer: {
    backgroundColor: '#F5F6FA',
    [theme.breakpoints.up('xs')]: {
      paddingBottom: '32px',
    },
  },
  logosContainer: {
    margin: theme.spacing(11, 0),
    backgroundColor: '#F5F6FA',
    borderRadius: 2,
    padding: theme.spacing(7, 0, 6, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(6, 0),
    },
  },
  containerMarginTop: {
    marginTop: theme.spacing(0),
  },
  featuredTitle: {
    marginTop: 80,
    fontWeight: 700,
    fontSize: 26,
    lineHeight: '120%',
  },
  emptyView: {
    paddingTop: '40px !important',
  },
  imageBannerContainer: {
    width: '100%',
    height: 'auto',
    span: {
      borderRadius: 8,
    },
  },
}));

interface IProps {
  markets: Market[];
  market: string;
}

const HomepageLayout: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const { markets, market } = props;
  const { classes } = useStyles();
  const [t] = useTranslation('public');
  const { i18n } = useTranslation();
  const { isAuthenticated } = useAuthProps();

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const banners = bannerData[market as keyof typeof bannerData];

  const tagsRow = tagsRows[market as keyof typeof tagsRows];
  const eventsPromoCards = eventsPromo()[market as keyof typeof eventsPromo];
  const dispatch = useDispatch();
  const [hashParams, setHashParams] = useHashParams();
  const [showJLiveCreatedBanner, setShowJLiveCreatedBanner] = useState(false);

  const onJLivePassportCreated = useCallback(() => {
    setShowJLiveCreatedBanner(true);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (hashParams.internalPassportCreated) {
      setHashParams({ ...hashParams, internalPassportCreated: undefined });
      onJLivePassportCreated();
    }
  }, [hashParams, onJLivePassportCreated, setHashParams]);

  const imgUrl = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
    ? mainBanner(market).sm
    : mainBanner(market).lg;

  const [hasContent, setHasContent] = useState(
    (eventsPromoCards ? eventsPromoCards?.length > 0 : false) || (tagsRow ? tagsRow?.length > 0 : false),
  );

  useEffect(() => {
    trackPage('Home Page', { 'Market Filter': market });
    setHasContent((eventsPromoCards ? eventsPromoCards?.length > 0 : false) || (tagsRow ? tagsRow?.length > 0 : false));
  }, [eventsPromoCards, market, tagsRow]);

  const onMarketChanged = (newMarket: string) => {
    trackPage('Home Page', { 'Market Filter': newMarket });

    dispatch(DetectMarketActions.selectMarket(newMarket));
  };

  const contentLoadingState = (context: string, isLoaded: boolean, hasItems: boolean) => {
    if (isLoaded) setHasContent(hasItems || hasContent);
  };

  const activeBanners = () => {
    const activeBanners = [] as typeof banners;
    banners.forEach((banner) => {
      if (!banner.startAt || isPast(new Date(banner.startAt))) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        activeBanners.push(banner);
      }
    });
    return activeBanners;
  };

  const hasActiveBanner = () => {
    if (!banners) return false;
    return activeBanners().length > 0;
  };

  const getDesktopBanner = (banner: any) => {
    if (i18n.language === 'fr' && banner?.desktopImageFr) return banner?.desktopImageFr;
    return banner?.desktopImage;
  };

  const getMobileBanner = (banner: any) => {
    if (i18n.language === 'fr' && banner?.mobileImageFr) return banner?.mobileImageFr;
    return banner?.mobileImage;
  };

  const getBannerLink = (banner: any) => {
    if (i18n.language === 'fr' && banner?.urlFr) return banner?.urlFr.link;
    return banner?.url.link;
  };

  return (
    market && (
      <Grid container className={classes.root}>
        {showJLiveCreatedBanner && (
          <Grid item xs={12}>
            <JLivePassportCreatedBanner
              position={isMobile ? 'relative' : 'absolute'}
              top={0}
              marginLeft={64}
              onClose={() => setShowJLiveCreatedBanner(false)}
            />
          </Grid>
        )}
        <Grid item xs={12} className={classes.bannerContainer}>
          <HomepageBanner
            description={t<string>('screen.homepage.description')}
            cover={imgUrl}
            title={<HomepageBannerTitle markets={markets} market={market} onSetMarket={onMarketChanged} />}
          />
        </Grid>
        <NoSsr>
          <Container style={{ maxWidth: 1168 }}>
            {!hasContent && (
              <Grid item xs={12} className={classes.emptyView}>
                <HomepageEmptyState />
              </Grid>
            )}
            {!isMobile && eventsPromoCards && (
              <Typography className={classes.featuredTitle} variant="h3">
                {t<string>('screen.homepage.featuredCategories')}
              </Typography>
            )}
            <Grid container spacing={4} className={classes.containerMarginTop}>
              {eventsPromoCards && (
                <Grid item xs={12}>
                  <EventPromoCardsContainer eventsPromoCards={eventsPromoCards} />
                </Grid>
              )}
              {hasActiveBanner() && (
                <>
                  {activeBanners().map((banner) => (
                    <Grid key={uuidv4()} item xs={isMobile ? 12 : 12 / activeBanners().length}>
                      <Link underline="none" href={getBannerLink(banner)}>
                        <Image
                          variant="aspectRatio"
                          objectFit="contain"
                          className={classes.imageBannerContainer}
                          src={isMobile ? getMobileBanner(banner) : getDesktopBanner(banner)}
                          alt="logo"
                          priority
                        />
                      </Link>
                    </Grid>
                  ))}
                </>
              )}
              {tagsRow && (
                <Grid item xs={12} key="EventsTagsRow">
                  <EventsTagsRow tagsRow={tagsRow} market={market} />
                </Grid>
              )}
              {isAuthenticated && (
                <EventsSectionContainer
                  titleKey="screen.homepage.recommendedForYou"
                  market={market}
                  ordering="-recommendation_rating"
                  itemsPerPage={3}
                  additionalFilters={{ exclude_hidden_events: true }}
                  context="recommendation"
                  contentLoadingState={contentLoadingState}
                />
              )}
              <EventsSectionContainer
                titleKey="organization.upcomingEvents"
                market={market}
                ordering="session_date"
                itemsPerPage={3}
                additionalFilters={{
                  ongoing_opportunities: false,
                  exclude_hidden_events: true,
                }}
                context="upcomingEvents"
                contentLoadingState={contentLoadingState}
              />
              <EventsSectionContainer
                titleKey="screen.homepage.popularNearYou"
                market={market}
                ordering="-popularity"
                itemsPerPage={3}
                additionalFilters={{ exclude_hidden_events: true }}
                context="popularity"
                contentLoadingState={contentLoadingState}
              />
              <EventsSectionContainer
                titleKey="screen.homepage.ongoingOpportunities"
                market={market}
                ordering="session_date"
                itemsPerPage={3}
                additionalFilters={{ ongoing_opportunities: true }}
                context="ongoing_opportunities"
                contentLoadingState={contentLoadingState}
              />
              <EventsSectionContainer
                titleKey="screen.homepage.volunteerism"
                market={market}
                ordering="session_date"
                itemsPerPage={3}
                additionalFilters={{}}
                context="volunteerism"
                contentLoadingState={contentLoadingState}
              />
              <Grid item xs={12}>
                <DynamicSectionsContainer market={market} itemsPerPage={3} contentLoadingState={contentLoadingState} />
              </Grid>
              <Grid item xs={12} className={classes.logosContainer}>
                <SupportersTittledLogosList />
              </Grid>
            </Grid>
          </Container>
        </NoSsr>
      </Grid>
    )
  );
};

export default memo(HomepageLayout);
