import React, { useMemo, useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Grid, IconButton, Menu, MenuItem, Theme, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'i18n';
import { makeStyles } from 'tss-react/mui';
import { Market } from 'api/general/models';
import DetroitMarketSponsor from 'components/svg/DetroitMarketSponsor.svg';
import SupportedBy from 'components/svg/SupportedBy.svg';
import SupportedByMobile from 'components/svg/SupportedByMobile.svg';
import CommunityCincinnatiLogo from 'components/svg/CommunityCincinnatiLogo.svg';
import JCCACincinnatiLogo from 'components/svg/JCCACincinnatiLogo.svg';
import MiamiPresentedBy from 'components/svg/MiamiPresentedBy.svg';

interface IProps {
  markets: Market[];
  market: string;
  onSetMarket: (market: string) => void;
}

const useStyles = makeStyles()((theme) => ({
  titleText: {
    textAlign: 'left',
    fontWeight: 700,
    fontSize: 42,
    lineHeight: '120%',
    letterSpacing: '-0.02em',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
      maxWidth: 500,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
      marginBottom: theme.spacing(2),
    },
  },
  market: {
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    color: theme.palette.primary.main,
    padding: 0,
    fontWeight: 700,
    fontSize: 42,
    lineHeight: '120%',
    background: 'none',
    border: 'none',
    letterSpacing: '-0.02em',
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
    },
  },
  presentedByMobile: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '0 !important',
  },
  MiamiPresentedBy: {
    marginTop: -24,
    [theme.breakpoints.down('sm')]: {
      marginTop: -12,
    },
  },
}));

const HomepageBannerTitle: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const { markets, market, onSetMarket } = props;
  const { classes } = useStyles();
  const [t] = useTranslation('public');
  const [anchorEl, setAnchorEl] = useState(null);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const selectMarket = markets.find(({ slug }) => market === slug);
  const handleOpenClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (slug: string) => () => {
    handleClose();
    onSetMarket(slug);
  };

  const marketComparator = (a: any, b: any) => {
    const marketsOrder = {
      cincinnati: 1,
      detroit: 2,
      miami: 3,
      montreal: 4,
      'northern-virginia': 5,
      'silicon-valley': 5,
      'test-cincinnati': 7,
      usa: 8,
      canada: 9,
      'north-america': 10,
      international: 11,
    };
    return marketsOrder[a.slug as keyof typeof marketsOrder] - marketsOrder[b.slug as keyof typeof marketsOrder];
  };

  const cincinnatiLogos = useMemo(
    () =>
      isMobile ? (
        <Grid container spacing={2} style={{ marginTop: -16 }} alignItems="center">
          <Grid className={classes.presentedByMobile} item>
            <SupportedByMobile />
          </Grid>
          <Grid container item xs style={{ padding: '0 16px' }}>
            <Grid item xs={12} style={{ height: 29, marginBottom: 1 }}>
              <CommunityCincinnatiLogo />
            </Grid>
            <Grid item xs={12} style={{ height: 34 }}>
              <JCCACincinnatiLogo />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2} style={{ marginTop: -32 }} alignItems="center">
          <Grid item>
            <SupportedBy />
          </Grid>
          <Grid item>
            <CommunityCincinnatiLogo />
          </Grid>
          <Grid item>
            <JCCACincinnatiLogo />
          </Grid>
        </Grid>
      ),
    [classes.presentedByMobile, isMobile],
  );

  return (
    <>
      <Typography className={classes.titleText} variant="h3">
        {t<string>('screen.homepage.title')}{' '}
        <Typography className={classes.market} onClick={handleOpenClick} component="span">
          {selectMarket?.title}
          <IconButton size="small">
            <ArrowDropDownIcon color="primary" />
          </IconButton>
        </Typography>
      </Typography>
      <Menu
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {markets
          .sort((a: any, b: any) => marketComparator(a, b))
          .map((market) => (
            <MenuItem
              key={market.slug}
              onClick={handleClick(market.slug)}
              divider={
                market.slug === 'test-cincinnati' || market.slug === 'north-america' || market.slug === 'silicon-valley'
              }
            >
              {market.title}
            </MenuItem>
          ))}
      </Menu>
      {market === 'detroit' && <DetroitMarketSponsor style={{ marginTop: -24 }} />}
      {market === 'cincinnati' && cincinnatiLogos}
      {market === 'miami' && <MiamiPresentedBy className={classes.MiamiPresentedBy} />}
    </>
  );
};

export default HomepageBannerTitle;
